import { useMemo } from 'react';
import PropTypes from 'prop-types';

import { IconContext } from 'react-icons';
import { SlSocialInstagram } from 'react-icons/sl';
import { GrLinkedin } from 'react-icons/gr';
import { ImPodcast } from 'react-icons/im';
import { TfiEmail } from 'react-icons/tfi';

import {
  instagramLink, linkedinLink, podcastLink, mailsPrivesLink,
} from '../Router/routes';

const SocialIcon = ({ icon }) => {
  const socialCSS = useMemo(() => ({
    size: '1.3rem',
    className: 'mx-2 text-white',
  }), []);

  return (
    <IconContext.Provider value={socialCSS}>
      {icon}
    </IconContext.Provider>
  );
};

SocialIcon.propTypes = {
  icon: PropTypes.element.isRequired,
};

const socialItems = [
  {
    link: instagramLink,
    icon: <SocialIcon icon={<SlSocialInstagram />} />,
  },
  {
    link: linkedinLink,
    icon: <SocialIcon icon={<GrLinkedin />} />,
  },
  {
    link: podcastLink,
    icon: <SocialIcon icon={<ImPodcast />} />,
  },
  {
    link: mailsPrivesLink,
    icon: <SocialIcon icon={<TfiEmail />} />,
  },
];

export default socialItems;
