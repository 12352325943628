import { combineReducers } from 'redux';

import authReducer from './auth.reducer';
import apiReducer from './api.reducer';
import workindiagReducer from './workindiag.reducer';
import reportReducer from './report.reducer';

export default combineReducers({
  auth: authReducer,
  api: apiReducer,
  workindiag: workindiagReducer,
  report: reportReducer,
});
