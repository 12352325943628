const { log } = console;

export const loadStorage = (key) => {
  try {
    const serializedState = localStorage.getItem(key);
    if (serializedState === null) {
      return undefined;
    }
    return JSON.parse(serializedState);
  } catch (error) {
    if (process.env.NODE_ENV === 'development') log(error);
    return undefined;
  }
};

export const saveStorage = (key, value) => {
  try {
    const serializedStorage = JSON.stringify(value);
    localStorage.setItem(key, serializedStorage);
  } catch (error) {
    if (process.env.NODE_ENV === 'development') log(error);
  }
};

export const removeStorage = (key) => {
  try {
    localStorage.removeItem(key);
  } catch (error) {
    if (process.env.NODE_ENV === 'development') log(error);
  }
};
