export const GET_REPORT = 'GET_REPORT';

export const getReport = (toolId, email) => ({
  type: GET_REPORT,
  toolId,
  email,
});

export const STORE_REPORT = 'STORE_REPORT';

export const storeReport = (date, tool, firstname, result) => ({
  type: STORE_REPORT,
  date,
  tool,
  firstname,
  result,
});

export const CLEAR_REPORT = 'CLEAR_REPORT';

export const clearReport = () => ({
  type: CLEAR_REPORT,
});
