import { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Sling as Hamburger } from 'hamburger-react';

import {
  Container, Nav, Navbar, Image, NavDropdown, Button,
} from 'react-bootstrap';

import logoWkCViolet from '../../assets/img/logo-wkc-violet.webp';

import pageItems from './pageItems';
import resultItems from './resultItems';

import './styles.scss';

const Header = ({ logout }) => {
  const [openBurger, setOpenBurger] = useState(false);

  const handleSelect = () => {
    setOpenBurger(false);
  };

  return (
    <header className="header">
      <Navbar
        className="bg-wkc-lightblue"
        collapseOnSelect
        expand="lg"
        fixed="top"
      >
        <Container>
          <Navbar.Brand as={Link} to="">
            <Image
              className="header__logo"
              src={logoWkCViolet}
              fluid
              loading="lazy"
              decoding="async"
            />
          </Navbar.Brand>
          <Navbar.Toggle className="header__toggler" aria-controls="navbar">
            <Hamburger
              toggled={openBurger}
              toggle={setOpenBurger}
              size={25}
              duration={1}
              rounded
              color="#DD0369"
            />
          </Navbar.Toggle>
          <Navbar.Collapse id="navbar">
            <Nav
              className="header__main-nav me-auto"
              onSelect={handleSelect}
            >
              <NavDropdown
                title={
                  <span className="dropdown-title">Visitez les pages</span>
                }
              >
                {
                  pageItems.map(({ label, link }) => (
                    <NavDropdown.Item
                      key={link}
                      as={Link}
                      eventKey={link}
                      to={link}
                      target="_blank"
                    >
                      {label}
                    </NavDropdown.Item>
                  ))
                }
              </NavDropdown>
              <NavDropdown
                title={
                  <span className="dropdown-title">Voir les résultats</span>
                }
              >
                {
                  resultItems.map(({ label, link }) => (
                    <NavDropdown.Item
                      key={link}
                      as={Link}
                      eventKey={link}
                      to={link}
                    >
                      {label}
                    </NavDropdown.Item>
                  ))
                }
              </NavDropdown>
            </Nav>
            <Nav className="header__logout-nav">
              <Link
                to="/dashboard"
              >
                <Button
                  variant="wkc-violet"
                  onClick={() => logout()}
                >
                  Se déconnecter
                </Button>
              </Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </header>
  );
};

Header.propTypes = {
  logout: PropTypes.func.isRequired,
};

export default Header;
