import PropTypes from 'prop-types';

import { ResponsiveRadar } from '@nivo/radar';

import './styles.scss';

const PillarsChart = ({ data }) => (
  <div className="pillars-chart text-center">
    <ResponsiveRadar
      data={data}
      keys={['score']}
      indexBy="pillar"
      maxValue={1}
      valueFormat=">-.0%"
      margin={{
        top: 30, right: 90, bottom: 30, left: 90,
      }}
      borderColor="#A20E53"
      gridLabelOffset={15}
      dotSize={5}
      dotColor="#A20E53"
      dotBorderWidth={2}
      colors="#A20E53"
      blendMode="multiply"
      motionConfig="wobbly"
      gridLevels={4}
      theme={{
        text: {
          fill: '#1D1D1B',
          fontSize: 14,
        },
      }}
    />
  </div>
);

PillarsChart.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({
    pillar: PropTypes.string,
    score: PropTypes.number,
  })).isRequired,
};

export default PillarsChart;
