import { useEffect, useMemo } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import { useForm } from 'react-hook-form';
import {
  Form, FloatingLabel, Button, Dropdown,
} from 'react-bootstrap';

import { IconContext } from 'react-icons';
import { TbArrowBackUp } from 'react-icons/tb';
import selectItems from './selectItems';

import './styles.scss';

const LoginForm = ({ submitForm }) => {
  const {
    register,
    handleSubmit,
    reset,
    setFocus,
    formState,
  } = useForm();

  const { isSubmitSuccessful } = formState;

  useEffect(() => {
    reset();
  }, [isSubmitSuccessful]);

  const onSubmit = async (data, event) => {
    event.preventDefault();
    setFocus('email');
    const { log } = console;
    const { email, password } = data;
    if (process.env.NODE_ENV === 'development') log(email, password);
    submitForm(email, password);
  };

  const backCSS = useMemo(() => ({
    size: '1.3rem',
    className: 'ms-3 text-blue',
  }), []);

  return (
    <Form className="form text-center" autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
      <Form.Group className="mb-3">
        <FloatingLabel
          controlId="floatingInput"
          label="Email"
        >
          <Form.Control
            type="email"
            placeholder="Email"
            {...register('email', { required: true })}
          />
        </FloatingLabel>
      </Form.Group>
      <Form.Group className="mb-3">
        <FloatingLabel
          controlId="floatingPassword"
          label="Mot de passe"
        >
          <Form.Control
            type="password"
            placeholder="Mot de passe"
            {...register('password', { required: true })}
          />
        </FloatingLabel>
      </Form.Group>
      <div className="form__footer d-flex justify-content-between">
        <Button
          variant="wkc-blue"
          type="submit"
        >
          Se connecter
        </Button>
        <Dropdown>
          <Dropdown.Toggle variant="wkc-blue-outlined d-flex align-items-center">
            Retour
            <IconContext.Provider value={backCSS}>
              <TbArrowBackUp />
            </IconContext.Provider>
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {
              selectItems.map(({ label, link }) => (
                <Dropdown.Item
                  key={link}
                  as={Link}
                  to={link}
                >
                  {label}
                </Dropdown.Item>
              ))
            }
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </Form>
  );
};

export default LoginForm;

LoginForm.propTypes = {
  submitForm: PropTypes.func.isRequired,
};
