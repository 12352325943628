import { useEffect } from 'react';

import './styles.scss';

const TestSyndromeImposteurBoard = () => {
  useEffect(() => {
  }, []);

  return (
    <div className="wd-board">
      <h1>TSI board</h1>
    </div>
  );
};

export default TestSyndromeImposteurBoard;
