// Open routes

export const workinDiagLink = '/workindiag';
export const testSyndromeImposteurLink = '/test-syndrome-imposteur';

// Protected routes

export const dashboardLink = '/dashboard';
export const workinDiagBoardLink = '/dashboard/workindiag';
export const testSyndromeImposteurBoardLink = '/dashboard/test-syndrome-imposteur';

// External routes

export const instagramLink = '//www.instagram.com/workincool/';
export const linkedinLink = '//www.linkedin.com/in/vanessarocherieux/';
export const podcastLink = '//smartlink.ausha.co/la-grande-roue';
export const mailsPrivesLink = '//workincool.com/mails-prives';
