import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  Container, Row, Col, Image,
} from 'react-bootstrap';

import PropTypes from 'prop-types';
import LoadingSpin from '../../../components/LoadingSpin';
import ChartsWorkinDiag from '../../../components/ChartsWorkinDiag';

import logoWKC from '../../../assets/img/logo-wkc-violet.webp';

import './styles.scss';

const WorkinDiagReport = ({
  email, loading, error, date, firstname, result, getResult,
}) => {
  const navigate = useNavigate();

  useEffect(() => {
    if (error) {
      navigate('/');
    }
  }, [error]);

  useEffect(() => {
    if (email && !error && !loading && result.length === 0) {
      getResult(1, email);
    }
  }, [email, error, result, loading]);

  return (
    <div className="workindiag-report">
      <header className="workindiag-report__header bg-wkc-lightyellow py-4">
        <Container>
          <Row className="justify-content-center">
            <Col xs={12} md={6}>
              <h1 className="text-uppercase ff--bold text-center">
                Ton <span className="lighted--violet">Workin'Diag</span>
              </h1>
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={{ span: 6, offset: 3 }}>
              <h4 className="ff-bold text-center text-uppercase">
                Le full check-up<br />
                pour entrepreneur·e
              </h4>
            </Col>
            <Col xs={12} md={3} className="d-none d-md-flex align-items-start">
              By
              <Image
                className="logo-wkc"
                src={logoWKC}
                alt="Logo Workin'Cool"
                fluid
                loading="lazy"
                decoding="async"
              />
            </Col>
          </Row>
        </Container>
      </header>
      <main className="workindiag-report__content pt-3">
        {
          loading && <LoadingSpin color="violet" />
        }
        {
          !loading && result.length > 0 && (
            <Container>
              <Row className="justify-content-center">
                <Col xs={12} md={6} className="text-center">
                  <h3>Hello <span className="ff--bold">{firstname}</span></h3>
                  <p>
                    Voici les résultats de
                    ton <span className="ff--bold text-wkc-violet">Workin'Diag</span> réalisé le {new Date(date).toLocaleDateString()}
                  </p>
                </Col>
              </Row>
              <Row className="justify-content-center py-3">
                <Col xs={12}>
                  <ChartsWorkinDiag data={result} />
                </Col>
              </Row>
            </Container>
          )
        }
      </main>
    </div>
  );
};

WorkinDiagReport.propTypes = {
  email: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
  error: PropTypes.bool.isRequired,
  date: PropTypes.string.isRequired,
  firstname: PropTypes.string.isRequired,
  result: PropTypes.array.isRequired,
  getResult: PropTypes.func.isRequired,
};

export default WorkinDiagReport;
