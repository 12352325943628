import { workinDiagLink, testSyndromeImposteurLink } from '../Router/routes';

const selectItems = [
  {
    label: 'Workin\'Diag',
    link: workinDiagLink,
  },
  {
    label: 'Test du syndrome de l\'imposteur',
    link: testSyndromeImposteurLink,
  },
];

export default selectItems;
