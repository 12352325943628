import { connect } from 'react-redux';
import WorkinDiagReport from '../../views/Reports/WorkinDiagReport';

import { getReport } from '../../actions/report.action';

const mapStateToProps = (state) => ({
  loading: state.api.loading,
  error: state.api.error,
  date: state.report.date,
  firstname: state.report.firstname,
  tool: state.report.tool,
  result: state.report.result,
});

const mapDispatchToProps = (dispatch) => ({
  getResult: (tooldId, email) => dispatch(getReport(tooldId, email)),
});

export default connect(mapStateToProps, mapDispatchToProps)(WorkinDiagReport);
