import apiTools from '../api';

import {
  fetchDataInit, fetchDataStart, fetchDataSuccess, fetchDataError,
} from '../actions/api.action';

import { GET_REPORT, storeReport } from '../actions/report.action';

export default (store) => (next) => async (action) => {
  const { dispatch } = store;
  const { log } = console;
  switch (action.type) {
    case GET_REPORT: {
      try {
        const { toolId, email } = action;
        console.log(toolId, email);
        dispatch(fetchDataInit());
        dispatch(fetchDataStart());
        const { data } = await apiTools.get(`/report/${toolId}?email=${email}`);
        const {
          message, date, tool, firstname, result,
        } = data;
        dispatch(storeReport(date, tool, firstname, result));
        dispatch(fetchDataSuccess(message));
        if (process.env.NODE_ENV === 'development') log(data);
      } catch (error) {
        const { message, detail } = error.response.data.error;
        dispatch(fetchDataError(`${message} - ${detail}`));
        if (process.env.NODE_ENV === 'development') log(error.response.data.error);
      }
      return next(action);
    }
    default:
      return next(action);
  }
};
