import { connect } from 'react-redux';
import Dashboard from '../../views/Dashboard';

import { rehydrate } from '../../actions/auth.action';

const mapStateToProps = (state) => ({
  logged: state.auth.logged,
});

const mapDispatchToProps = (dispatch) => ({
  rehydrate: (token) => dispatch(rehydrate(token)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
