import { connect } from 'react-redux';
import Header from '../../components/Header';

import { logout } from '../../actions/auth.action';

const mapStateToProps = null;

const mapDispatchToProps = (dispatch) => ({
  logout: () => dispatch(logout()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
