import { useMemo } from 'react';
import { IconContext } from 'react-icons';
import { HiLink } from 'react-icons/hi';

import { workinDiagLink, testSyndromeImposteurLink } from '../Router/routes';

const Icon = () => {
  const iconCSS = useMemo(() => ({
    size: '1.4rem',
    className: 'mx-2',
  }), []);

  return (
    <IconContext.Provider value={iconCSS}>
      <HiLink />
    </IconContext.Provider>
  );
};

const pageItems = [
  {
    label: (
      <>
        <Icon />
        <span>Workin'Diag</span>
      </>
    ),
    link: workinDiagLink,
  },
  {
    label: (
      <>
        <Icon />
        <span>Test du syndrome de l'imposteur</span>
      </>
    ),
    link: testSyndromeImposteurLink,
  },
];

export default pageItems;
