import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import store from '../store';

import Router from '../components/Router';
import Footer from '../components/Footer';

import './styles.scss';

const App = () => (
  <main className="main">
    <Provider store={store}>
      <BrowserRouter>
        <Router />
        <Footer />
      </BrowserRouter>
    </Provider>
  </main>
);

export default App;
