import { connect } from 'react-redux';
import Login from '../../views/Dashboard/Login';

import { submitLogin } from '../../actions/auth.action';

const mapStateToProps = (state) => ({
  loading: state.api.loading,
  success: state.api.success,
  error: state.api.error,
  message: state.api.message,
});

const mapDispatchToProps = (dispatch) => ({
  submitLogin: (email, password) => dispatch(submitLogin(email, password)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
