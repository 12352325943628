import { Outlet } from 'react-router-dom';

import './styles.scss';

const Report = () => (
  <div className="report">
    <Outlet />
  </div>
);

export default Report;
