import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import {
  Nav, Tab,
} from 'react-bootstrap';

import PillarsChart from './charts/PillarsChart';
import ComponentsChart from './charts/ComponentsChart';

import './styles.scss';

const ChartsWorkinDiag = ({ data }) => {
  const [pillarsData, setPillarsData] = useState([]);
  const [emotionsComponents, setEmotionsComponents] = useState([]);
  const [efficienceComponents, setEfficienceComponents] = useState([]);
  const [strategieComponents, setStrategieComponents] = useState([]);
  const [mindsetComponents, setMindsetComponents] = useState([]);

  useEffect(() => {
    setPillarsData(data.map(({ pillar, score }) => ({
      pillar,
      score: score / 100,
    })));
    setEmotionsComponents(data[0].components.map(({ component, score }) => ({
      component: component.split(' - ')[0],
      score: score / 100,
    })));
    setEfficienceComponents(data[1].components.map(({ component, score }) => ({
      component: component.split(' - ')[0],
      score: score / 100,
    })));
    setStrategieComponents(data[2].components.map(({ component, score }) => ({
      component: component.split(' - ')[0],
      score: score / 100,
    })));
    setMindsetComponents(data[3].components.map(({ component, score }) => ({
      component: component.split(' - ')[0],
      score: score / 100,
    })));
  }, [data]);

  return (
    <div className="charts-wd">
      <Tab.Container defaultActiveKey="pillars">
        <Nav variant="pills" className="justify-content-center mb-3">
          <Nav.Item>
            <Nav.Link eventKey="pillars" className="pillars">
              Les 4 piliers
            </Nav.Link>
          </Nav.Item>
        </Nav>
        <Nav variant="pills" className="flex-nowrap justify-content-center">
          <Nav.Item>
            <Nav.Link eventKey="emotions" className="emotions mx-0 mx-md-3">
              Émotions
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey="efficience" className="efficience mx-0 mx-md-3">
              Efficience
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey="strategie" className="strategie mx-0 mx-md-3">
              Stratégie
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey="mindset" className="mindset mx-0 mx-md-3">
              Mindset
            </Nav.Link>
          </Nav.Item>
        </Nav>
        <Tab.Content>
          <Tab.Pane eventKey="pillars">
            <PillarsChart data={pillarsData} />
          </Tab.Pane>
          <Tab.Pane eventKey="emotions">
            <ComponentsChart color="#DD0369" data={emotionsComponents} />
          </Tab.Pane>
          <Tab.Pane eventKey="efficience">
            <ComponentsChart color="#F0B40E" data={efficienceComponents} />
          </Tab.Pane>
          <Tab.Pane eventKey="strategie">
            <ComponentsChart color="#F35C04" data={strategieComponents} />
          </Tab.Pane>
          <Tab.Pane eventKey="mindset">
            <ComponentsChart color="#029FB2" data={mindsetComponents} />
          </Tab.Pane>
        </Tab.Content>
      </Tab.Container>
    </div>
  );
};

ChartsWorkinDiag.propTypes = {
  data: PropTypes.array.isRequired,
};

export default ChartsWorkinDiag;
