export const GET_WORKINDIAG_USERS = 'GET_WORKINDIAG_USERS';

export const getWorkinDiagUsers = () => ({
  type: GET_WORKINDIAG_USERS,
});

export const STORE_WORKINDIAG_USERS = 'STORE_WORKINDIAG_USERS';

export const storeWorkinDiagUsers = (users) => ({
  type: STORE_WORKINDIAG_USERS,
  users,
});

export const GET_WORKINDIAG_RESULT = 'GET_WORKINDIAG_RESULT';

export const getWorkinDiagResult = (userId) => ({
  type: GET_WORKINDIAG_RESULT,
  userId,
});

export const STORE_WORKINDIAG_RESULT = 'STORE_WORKINDIAG_RESULT';

export const storeWorkinDiagResult = (result) => ({
  type: STORE_WORKINDIAG_RESULT,
  result,
});

export const CLEAR_WORKINDIAG_DATA = 'CLEAR_WORKINDIAG_DATA';

export const clearWorkinDiagData = () => ({
  type: CLEAR_WORKINDIAG_DATA,
});
