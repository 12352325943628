export const REHYDRATE = 'REHYDRATE';

export const rehydrate = (accessToken) => ({
  type: REHYDRATE,
  accessToken,
});

export const SUBMIT_LOGIN = 'SUBMIT_LOGIN';

export const submitLogin = (email, password) => ({
  type: SUBMIT_LOGIN,
  email,
  password,
});

export const LOGIN = 'LOGIN';

export const login = (accessToken) => ({
  type: LOGIN,
  accessToken,
});

export const LOGOUT = 'LOGOUT';

export const logout = () => ({
  type: LOGOUT,
});
