import { useEffect } from 'react';
import PropTypes from 'prop-types';

import './styles.scss';

const TallyForm = ({ link }) => {
  useEffect(() => {
    const widgetScriptSrc = 'https://tally.so/widgets/embed.js';

    const load = () => {
      // Load Tally embeds
      if (typeof Tally !== 'undefined') {
        // eslint-disable-next-line no-undef
        Tally.loadEmbeds();
        return;
      }

      // Fallback if window.Tally is not available
      document
        .querySelectorAll('iframe[data-tally-src]:not([src])')
        .forEach((iframeEl) => {
          // eslint-disable-next-line no-param-reassign
          iframeEl.src = iframeEl.dataset.tallySrc;
        });
    };

    // If Tally is already loaded, load the embeds
    if (typeof Tally !== 'undefined') {
      load();
      return;
    }

    // If the Tally widget script is not loaded yet, load it
    if (document.querySelector(`script[src="${widgetScriptSrc}"]`) === null) {
      const script = document.createElement('script');
      script.src = widgetScriptSrc;
      script.onload = load;
      script.onerror = load;
      document.body.appendChild(script);
    }
  }, []);

  return (
    <div className="tally-form">
      <iframe
        data-tally-src={link}
        loading="lazy"
        width="100%"
        height="580"
        frameBorder="0"
        marginHeight="0"
        marginWidth="0"
        // onLoad={}
        title="Workin'Diag entrepreneur"
      />
      <div className="hide" />
    </div>
  );
};

TallyForm.propTypes = {
  link: PropTypes.string.isRequired,
};

export default TallyForm;
