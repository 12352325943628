import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Container, Row, Col, Card,
} from 'react-bootstrap';

import LoginForm from '../../../components/LoginForm';
import LoadingSpin from '../../../components/LoadingSpin';

import logoWkC from '../../../assets/img/logo-wkc-blue.webp';

import './styles.scss';

const Login = ({
  submitLogin, loading, error, message,
}) => {
  const [showError, setShowError] = useState(false);
  const [errorStatus, setErrorStatus] = useState(null);
  const submitForm = async (email, password) => {
    submitLogin(email, password);
  };

  useEffect(() => {
    if (error) {
      const status = Number(message.split(' - ')[0]);
      setErrorStatus(status);
      setShowError(true);
    }
  }, [error]);

  useEffect(() => {
    if (errorStatus) {
      setTimeout(() => {
        setErrorStatus(null);
        setShowError(false);
      }, 4000);
    }
  }, [errorStatus]);

  return (
    <div className="login">
      <Container>
        <Row className="justify-content-center">
          <Col xs={12} md={4}>
            <Card className="login__card">
              <Card.Header>
                <Card.Title as="h3" className="ff--bold text-wkc-blue d-flex align-items-center justify-content-between mb-0">
                  Dashboard
                  <Card.Img src={logoWkC} />
                </Card.Title>
              </Card.Header>
              <Card.Body>
                {
                  loading && <LoadingSpin color="violet" />
                }
                <div className={loading ? 'hide' : ''}>
                  <Card.Title>
                    {
                      showError && errorStatus === 401 && <span className="text-wkc-violet">Erreur de connexion...</span>
                    }
                  </Card.Title>
                  <LoginForm submitForm={submitForm} />
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

Login.propTypes = {
  submitLogin: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  error: PropTypes.bool.isRequired,
  message: PropTypes.string.isRequired,
};

export default Login;
