import { STORE_WORKINDIAG_USERS, STORE_WORKINDIAG_RESULT, CLEAR_WORKINDIAG_DATA } from '../actions/workindiag.action';

export const initialState = {
  users: [],
  currentUserResult: {
    date: null,
    firstname: null,
    result: [],
  },
};

const reducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case STORE_WORKINDIAG_USERS:
      return {
        ...state,
        users: action.users,
      };
    case STORE_WORKINDIAG_RESULT:
      return {
        ...state,
        currentUserResult: action.result,
      };
    case CLEAR_WORKINDIAG_DATA:
      return {
        ...state,
        users: [],
        currentUserResult: {
          date: null,
          firstname: null,
          result: [],
        },
      };
    default:
      return state;
  }
};

export default reducer;
