import { useMemo } from 'react';
import { IconContext } from 'react-icons';
import { RiLineChartLine } from 'react-icons/ri';

import { workinDiagBoardLink, testSyndromeImposteurBoardLink } from '../Router/routes';

const Icon = () => {
  const iconCSS = useMemo(() => ({
    size: '1.4rem',
    className: 'mx-2',
  }), []);

  return (
    <IconContext.Provider value={iconCSS}>
      <RiLineChartLine />
    </IconContext.Provider>
  );
};

const resultItems = [
  {
    label: (
      <div className="d-flex align-items-center">
        <Icon />
        <span>Workin'Diag</span>
      </div>
    ),
    link: workinDiagBoardLink,
  },
  {
    label: (
      <div className="d-flex align-items-center">
        <Icon />
        <span>Test du syndrome de l'imposteur</span>
      </div>
    ),
    link: testSyndromeImposteurBoardLink,
  },
];

export default resultItems;
