import { Outlet, useLocation } from 'react-router-dom';

import { Container, Row, Col } from 'react-bootstrap';

import Header from '../../../containers/Header';

import './styles.scss';

const Content = () => {
  const { pathname } = useLocation();

  return (
    <div className="content">
      <Header />
      {
        pathname === '/dashboard' && (
          <Container>
            <Row className="justify-content-center py-5">
              <Col xs={12} md={6} className="text-center">
                <h1 className="ff--bold">Hello Vanessa</h1>
                <p className="fs-4">
                  Bienvenue sur ton dashboard,<br />
                  sélectionne dans le menu le résultat que tu souhaites voir.
                </p>
                <p className="fs-4">
                  Enjoy !
                </p>
              </Col>
            </Row>
          </Container>
        )
      }
      <Outlet />
    </div>
  );
};

export default Content;
