import PropTypes from 'prop-types';

import { ResponsiveBar } from '@nivo/bar';

import './styles.scss';

const ComponentsChart = ({ color, data }) => (
  <div className="components-chart text-center">
    <ResponsiveBar
      data={data}
      keys={[
        'score',
      ]}
      indexBy="component"
      layout="horizontal"
      colors={color}
      labelTextColor="#FFF"
      padding={0.3}
      margin={{
        top: 30, right: 30, bottom: 30, left: 180,
      }}
      minValue={0}
      maxValue={1}
      valueFormat=">-.0%"
      enableGridY={false}
      axisBottom={false}
      axisLeft={{
        tickPadding: 5,
        tickRotation: 0,
        truncateTickAt: 0,
      }}
      theme={{
        text: {
          fill: '#1D1D1B',
          fontSize: 14,
        },
      }}
    />
  </div>
);

ComponentsChart.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({
    component: PropTypes.string,
    score: PropTypes.number,
    scoreColor: PropTypes.string,
    empty: PropTypes.number,
    emptyColor: PropTypes.string,
  })).isRequired,
  color: PropTypes.string.isRequired,
};

export default ComponentsChart;
