import { useState, useEffect } from 'react';

import './styles.scss';
import PropTypes from 'prop-types';

const LoadingSpin = ({ color }) => {
  const [dotsColor, setDorsColor] = useState('violet');

  useEffect(() => {
    setDorsColor(color);
  }, [color]);

  return (
    <div className="dot-container">
      <div className={`dot-spin--${dotsColor}`} />
    </div>
  );
};

LoadingSpin.propTypes = {
  color: PropTypes.string.isRequired,
};

export default LoadingSpin;
