import { LOGIN, LOGOUT } from '../actions/auth.action';

export const initialState = {
  logged: false,
  accessToken: null,
};

const reducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case LOGIN:
      return {
        ...state,
        logged: true,
        accessToken: action.accessToken,
      };
    case LOGOUT:
      return {
        ...state,
        logged: false,
        accessToken: null,
      };
    default:
      return state;
  }
};

export default reducer;
