import {
  FETCH_DATA_INIT, FETCH_DATA_START, FETCH_DATA_SUCCESS, FETCH_DATA_ERROR,
} from '../actions/api.action';

export const initialState = {
  loading: false,
  success: false,
  error: false,
  message: '',
};

const reducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case FETCH_DATA_INIT:
      return {
        ...state,
        loading: false,
        success: false,
        error: false,
        message: '',
      };
    case FETCH_DATA_START:
      return {
        ...state,
        loading: true,
        success: false,
        error: false,
        message: '',
      };
    case FETCH_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        error: false,
        message: action.message,
      };
    case FETCH_DATA_ERROR:
      return {
        ...state,
        loading: false,
        success: false,
        error: true,
        message: action.message,
      };
    default:
      return state;
  }
};

export default reducer;
