/* eslint-disable no-promise-executor-return */
import apiTools from '../api';

import {
  fetchDataInit, fetchDataStart, fetchDataSuccess, fetchDataError,
} from '../actions/api.action';

import {
  GET_WORKINDIAG_USERS,
  storeWorkinDiagUsers,
  GET_WORKINDIAG_RESULT,
  storeWorkinDiagResult,
} from '../actions/workindiag.action';

export default (store) => (next) => async (action) => {
  const { dispatch } = store;
  const { log } = console;
  switch (action.type) {
    case GET_WORKINDIAG_USERS: {
      const headers = {
        headers: {
          Authorization: `Bearer ${store.getState().auth.accessToken}`,
        },
      };
      try {
        dispatch(fetchDataInit());
        dispatch(fetchDataStart());
        const { data } = await apiTools.get('/admin/users?toolId=1', headers);
        const { message, users } = data;
        dispatch(storeWorkinDiagUsers(users));
        dispatch(fetchDataSuccess(message));
        if (process.env.NODE_ENV === 'development') log(data);
      } catch (error) {
        const { message, detail } = error.response.data.error;
        dispatch(fetchDataError(`${message} - ${detail}`));
        if (process.env.NODE_ENV === 'development') log(error.response.data.error);
      }
      return next(action);
    }
    case GET_WORKINDIAG_RESULT: {
      const { userId } = action;
      const headers = {
        headers: {
          Authorization: `Bearer ${store.getState().auth.accessToken}`,
        },
      };
      try {
        dispatch(fetchDataInit());
        dispatch(fetchDataStart());
        const { data } = await apiTools.get(`/admin/result?toolId=1&userId=${userId}`, headers);
        const {
          message, date, firstname, result,
        } = data;
        dispatch(storeWorkinDiagResult({
          date,
          firstname,
          result,
        }));
        dispatch(fetchDataSuccess(message));
        if (process.env.NODE_ENV === 'development') log(data);
      } catch (error) {
        const { message, detail } = error.response.data.error;
        dispatch(fetchDataError(`${message} - ${detail}`));
        if (process.env.NODE_ENV === 'development') log(error.response.data.error);
      }
      return next(action);
    }
    default:
      return next(action);
  }
};
