export const FETCH_DATA_INIT = 'FETCH_DATA_INIT';

export const fetchDataInit = () => ({
  type: FETCH_DATA_INIT,
});

export const FETCH_DATA_START = 'FETCH_DATA_START';

export const fetchDataStart = () => ({
  type: FETCH_DATA_START,
});

export const FETCH_DATA_SUCCESS = 'FETCH_DATA_SUCCESS';

export const fetchDataSuccess = (message) => ({
  type: FETCH_DATA_SUCCESS,
  message,
});

export const FETCH_DATA_ERROR = 'FETCH_DATA_ERROR';

export const fetchDataError = (message) => ({
  type: FETCH_DATA_ERROR,
  message,
});
