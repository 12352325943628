import axios from 'axios';

const apiTools = axios.create({
  baseURL: process.env.NODE_ENV === 'development' ? 'http://localhost:3001' : 'https://api.workincool.com/apitools/v1',
  headers: {
    'api-key': '66dc0bcaaffa36f0a210f9514d675b094ada87ff9d3bfc9444b285587d4cdaa0',
  },
});

export default apiTools;
