import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import Login from '../../containers/Login';
import Content from './Content';

import { loadStorage } from '../../utils/localStorage.util';

import './styles.scss';

const Dashboard = ({ logged, rehydrate }) => {
  const [storedToken, setStoredToken] = useState(null);

  useEffect(() => {
    if (!logged) {
      setStoredToken(loadStorage('wkc-tools-token'));
    }
  }, [logged]);

  useEffect(() => {
    if (storedToken) {
      rehydrate(storedToken);
    }
  }, [storedToken]);

  return (
    <div className="dashboard">
      {
        logged ? <Content /> : <Login />
      }
    </div>
  );
};

Dashboard.propTypes = {
  logged: PropTypes.bool.isRequired,
  rehydrate: PropTypes.func.isRequired,
};

export default Dashboard;
