import { connect } from 'react-redux';
import WorkinDiagBoard from '../../views/Dashboard/Content/WorkinDiagBoard';

import { getWorkinDiagUsers, getWorkinDiagResult } from '../../actions/workindiag.action';

const mapStateToProps = (state) => ({
  users: state.workindiag.users,
  loading: state.api.loading,
  userResult: state.workindiag.currentUserResult,
});

const mapDispatchToProps = (dispatch) => ({
  getWorkinDiagUsers: () => dispatch(getWorkinDiagUsers()),
  getWorkinDiagResult: (userId) => dispatch(getWorkinDiagResult(userId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(WorkinDiagBoard);
