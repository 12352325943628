import { STORE_REPORT, CLEAR_REPORT } from '../actions/report.action';

export const initialState = {
  date: '',
  tool: '',
  firstname: '',
  result: [],
};

const reducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case STORE_REPORT:
      return {
        ...state,
        date: action.date,
        tool: action.tool,
        firstname: action.firstname,
        result: action.result,
      };
    case CLEAR_REPORT:
      return {
        ...state,
        date: '',
        tool: '',
        firstname: '',
        result: [],
      };
    default:
      return state;
  }
};

export default reducer;
